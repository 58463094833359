/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import { Page } from 'Component/Header/Header.config';
import { LOADING_TIME } from 'Route/CmsPage/CmsPage.config';
import {
    CmsPageContainer as SourceContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';
import history from 'Util/History';
import { debounce } from 'Util/Request/Debounce';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { isHomePageUrl } from 'Util/Url';

export {
    mapDispatchToProps,
    mapStateToProps,
};

/** @namespace Satisfly/Route/CmsPage/Container */
export class CmsPageContainer extends SourceContainer {
    onPageLoad() {
        const {
            updateMeta,
            setHeaderState,
            updateBreadcrumbs,
        } = this.props;
        const { cmsPage } = this.props;
        const { location: { pathname } } = history;
        const {
            content_heading,
            meta_title,
            title,
            meta_description,
            meta_keywords,
        } = cmsPage;

        setLoadedFlag();
        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(cmsPage);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: Page.CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack(),
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
